<template>
  <div>
    <button @click="fetchReonomyContacts" :disabled="fetching">Find Contacts</button>
    <span v-if="fetching">Finding Contacts...</span>
    <table class="table" v-if="flattenedPersons.length > 0">
      <thead>
        <tr>
          <th style="width: 25%">Name</th>
          <th style="width: 30%">Company</th>
          <th style="width: 20%">Title</th>
          <th style="width: 20%; text-align:right">Contact Info</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="person in flattenedPersons" style="margin-bottom: 10px">
          <td class="fw-bold">{{ person.name }}</td>
          <td>{{ person.company }}</td>
          <td>{{ person.title }}</td>
          <td style="text-align:right">
            <a href="#" @click.prevent="openDrawer(person)">Show</a>
          </td>
        </tr>
      </tbody>
    </table>

    <ContactDrawer
      :visible="drawerVisible"
      :person="selectedPerson"
      :property="property"
      :importedContacts="selectedImportedContacts"
      @contactCreate="createContact"
      @contactEnroll="enrollContact"
      @close="closeDrawer"/>
  </div>
</template>

<script>
import axios from "axios"
import { Notyf } from 'notyf'
import ContactDrawer from './ContactDrawer.vue'
import { reo } from '../json/reonomy.js'

export default {
  components: {
    ContactDrawer
  },
  props: [
    'property',
    'sequence'
  ],
  data: function() {
    return {
      contacts: [],
      drawerVisible: false,
      selectedPerson: null,
      flattenedPersons: [],
      fetching: false,
      selectedImportedContacts: []
    }
  },
  mounted() {
    this.flattenContacts(this.contacts)
  },
  watch: {
    selectedPerson: function(newPerson, oldPerson) {
      this.fetchImportedReonomyContacts()
    }
  },
  methods: {
    openDrawer(contact) {
      this.selectedPerson = contact;
      this.drawerVisible = true;
    },
    closeDrawer() {
      this.selectedPerson = null;
      this.drawerVisible = false;
    },
    flattenContacts(contacts) {
      contacts.forEach(contact => {
        contact.persons.forEach(person => {
          this.flattenedPersons.push({
            id: person.id,
            contactType: contact.contact_type,
            name: person.display,
            title: person.jobs.length > 0 ? person.jobs[0].title : 'Individual',
            company: contact.contact_type === 'company' ? contact.company.name : "",
            emails: person.emails,
            phones: person.phones,
            first_name: person.first_name,
            last_name: person.last_name,
            addresses: person.addresses
          });
        });
      });
    },
    fetchReonomyContacts() {
      console.log('fetchReonomyContacts');
      // This is used in development
      // console.log(reo)
      // this.flattenContacts(reo.contacts)
    
      this.fetching = true
      axios.get(`/reonomy/${this.$route.params.id}`)
        .then(response => {
          console.log(response)
          this.fetching = false
          this.flattenContacts(response.data.contacts)
      })
      .catch(error => {
        console.log(error)
      })
    },
    createContact(person, target) {
      target.disabled = true
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      axios.post(`/properties/${this.property.id}/contacts/build_from_reonomy`, { person: person })
        .then(response => {
          notyf.success('Contact applied to close.io')
          target.disabled = false
          this.fetchImportedReonomyContacts()
        })
        .catch(error => {
          notyf.error('Oops, Contact could not be updated!');
          target.disabled = false
        })
    },
    enrollContact(person, target) {
      target.disabled = true
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });

      const contacts = this.selectedImportedContacts.filter((e) => e.email != null)

      let promises = contacts.map((contact) => {
        axios.post('crm/sequences/subscribe_contact', {
          contact_id: contact.close_io_contact_id,
          sequence_id: this.sequence.id
        })
      })
      axios.all(promises).then(response => {
        console.log(response)
        notyf.success('Contacts Enrolled in Call Borrower Sequence!')
        target.disabled = false
      }).catch(error => {
        target.disabled = false
      })
    },

    fetchImportedReonomyContacts() {
      if (this.selectedPerson) {
        axios.get(`/properties/${this.property.id}/contacts/reonomy_contacts?person_id=${ this.selectedPerson.id }`)
        .then(response => {
          this.selectedImportedContacts = response.data.reonomy_contacts
        })
      }
    }
  }
}
</script>

