<template>
  <div class="col-4">
    <DebtYieldCalculator :property="property" />
        
    <div v-if="!propertyUsesUnits()" class="content-box-green">
      <div class="title">Tenancy {{ tenancyLabel() }}</div>
      
      <table class="table-sm table-bordered">
        <tbody>
          <tr v-for="(data, tenant) in tenantsWithSqFootage">
            <td width="50%">{{ tenant }}</td>
            <td v-if="data.sq_footage" width="25%">{{ commaDelimited(data.sq_footage) }} sq ft</td>
            <td v-if="data.lease_exp" class="text-end" width="25%">{{ toLocaleDate(data.lease_exp) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else-if="propertyHasGoogleData">
      <div class="content-box-green">
        <div class="title">Google Rating</div>
        <table class="table-sm table-bordered">
          <tbody>
            <tr>
              <td colspan="2">
                <img class="p-0 w-100" :src="googlePlaceImageUrl" />
              </td>
            </tr>
            <tr>
              <td>{{ property.google_data['name'] }}</td>
              <td>
                <span>{{ property.google_data['rating'] }}</span>
                <GooglePlaceRating :property="property"/>
                <span>{{ property.google_data['user_ratings_total'] }} reviews</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="content-box-green">
      <div class="title">Demos - 5 Mile</div>
      
      <table class="table-sm table-bordered">
        <tbody>
          <tr>
            <td>Population</td>
            <td>{{ commaDelimited(this.population) }}</td>
          </tr>
          <tr>
            <td>Asian Demo</td>
            <td>{{ commaDelimited(this.asianPopulation) }}</td>
          </tr>
          <tr>
            <td>Average Income</td>
            <td>${{ commaDelimited(property.average_income) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="content-box-green">
      <div class="title">Investment</div>
      
      <table class="table-sm table-bordered">
        <tbody>
          <tr>
            <td>NOI as of {{ property.loan.financials_reporting_date }}</td>
            <td>${{ commaDelimited(property.loan.noi) }}</td>
          </tr>
          <tr>
            <td>Balance per {{ this.unitLabel(true) }}</td>
            <td>${{ commaDelimited(Math.round(balance_per_ft_or_unit)) }}</td>
          </tr>
          <tr>
            <td>Appraisal per {{ this.unitLabel(true) }}</td>
            <td>${{ commaDelimited(parseInt(this.appraisal_per_unit)) }}</td>
          </tr>
          <tr>
            <td>CAP Rate on Appraisal</td>
            <td>{{ capRateOnAppraisal }}%</td>
          </tr>
          <tr>
            <td>CAP Rate on Balance</td>
            <td>{{ capRateOnBalance }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="content-box-green">
      <div class="title">Appraisal & Balances</div>
      <table class="table-sm table-bordered">
        <tbody>
          <tr>
            <td>Appraisal as of {{ toLocaleDate(property.loan.appraisal_date) }}</td>
            <td>${{ commaDelimited(property.loan.appraisal) }}</td>
          </tr>
          <tr>
            <td>Total Exposure</td>
            <td>${{ commaDelimited(this.totalExposure) }}</td>
          </tr>
          <tr>
            <td>Current Balance</td>
            <td>${{ commaDelimited(property.total_balance) }}</td>
          </tr>
          <tr>
            <td>Original Balance</td>
            <td>${{ commaDelimited(this.originalBalance) }}</td>
          </tr>
          <tr>
            <td>Loan Pieces?</td>
            <td>{{ property.has_loan_pieces }} <span v-show="property.recent_loan_pieces.length > 1">({{ property.recent_loan_pieces.length }})</span></td>
          </tr>
          <tr>
            <td>Multiproperty Loan?</td>
            <td>{{ this.multiPropertyLoan }} <span v-show="otherPropertiesInLoan.length > 0">({{ otherPropertiesInLoan.length + 1 }})</span></td>
          </tr>
          <tr v-if="property.mezz_loans.length > 0">
            <td>Has Mezz?</td>
            <td>Yes</td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="content-box-green">
      <div class="title">Loan</div>
      
      <table class="table-sm table-bordered">
        <tbody>
          <tr>
            <td>Special Servicer</td>
            <td>{{ property.loan.special_servicer }}</td>
          </tr>
          <tr>
            <td>Loan Purpose</td>
            <td>{{ property.loan.loan_purpose }}</td>
          </tr>
          <tr v-if="property.loan.watchlist_status == 'Yes'">
            <td>Watchlist Transfer Date</td>
            <td>{{ toLocaleDate(property.loan.watchlist_transfer_date) }}</td>
          </tr>
          <tr v-if="property.loan.special_service_status == 'Yes'">
            <td>Spec Serv Transfer Date</td>
            <td>{{ toLocaleDate(property.loan.special_servicing_transfer_date) }}</td>
          </tr>
          <tr>
            <td>Maturity Date</td>
            <td>{{ toLocaleDate(property.loan.maturity_date) }}</td>
          </tr>
          <tr>
            <td>Origination Date</td>
            <td>{{ toLocaleDate(property.loan.origination_date) }}</td>
          </tr>
          <tr>
            <td>Rate</td>
            <td>{{ property.loan.loan_rate }}%</td>
          </tr>
          <tr>
            <td>Sponsor</td>
            <td>{{ property.loan.sponsor }}</td>
          </tr>
          <tr>
            <td>CMBS Deal Name</td>
            <td>{{ property.loan.cmbs_deal_name }}</td>
          </tr>
          <tr>
            <td>DSCR</td>
            <td>{{ property.loan.dscr_noi }}</td>
          </tr>
          <tr>
            <td>B Piece Buyer</td>
            <td 
              contentEditable="true" 
              :data-id="property.loan.id" 
              @keydown.enter="updateBPieceBuyer">
                {{ property.b_piece_buyers }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div v-if="property.mezz_loans.length > 0" v-for="mezz_loan in property.mezz_loans">
      <div class="content-box-green">
        <div class="title">Mezz Loan</div>
      
        <table class="table-sm table-bordered">
          <tbody>
            <tr>
              <td>Delinquency Status</td>
              <td>{{ mezz_loan.delinquency_status }}</td>
            </tr>
            <tr>
              <td>Lender</td>
              <td>{{ mezz_loan.lender }}</td>
            </tr>
            <tr>
              <td>Borrower</td>
              <td>{{ mezz_loan.borrower }}</td>
            </tr>
            <tr>
              <td>Maturity Date</td>
              <td>{{ toLocaleDate(mezz_loan.maturity_date) }}</td>
            </tr>
            <tr>
              <td>Origination Date</td>
              <td>{{ toLocaleDate(mezz_loan.origination_date) }}</td>
            </tr>
            <tr>
              <td>Rate</td>
              <td>{{ (mezz_loan.loan_rate * 100).toPrecision(2) }}%</td>
            </tr>
            <tr>
              <td>Original Balance</td>
              <td>${{ commaDelimited(mezz_loan.original_balance) }}</td>
            </tr>
            <tr>
              <td>Comments</td>
              <td>{{ mezz_loan.comments }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
    <div v-if="otherPropertiesInLoan.length > 0" class="content-box-green">
      <div class="title">Other Properties in Loan</div>
      
      <ul class="bordered">
        <li v-for="property in otherPropertiesInLoan">
          <router-link :to="`/property/${property.id}`">
            {{ property.name }}
          </router-link>
        </li>
      </ul>
    </div>
    
    <button class="btn btn-danger" @click='destroyProperty'>
      Delete Property
    </button>
  </div>
</template>

<script>
import axios from "axios";

import GooglePlaceRating from './components/GooglePlaceRating.vue'
import RefinanceCalculator from './components/RefinanceCalculator.vue'
import DebtYieldCalculator from './components/DebtYieldCalculator.vue'

import { isEmpty } from 'lodash'

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default {
  components: { 
    GooglePlaceRating,
    RefinanceCalculator,
    DebtYieldCalculator
  },
  props: [
    "property"
  ],
  data: function() {
    return {
      otherPropertiesInLoan: [],
      loanPieces: []
    }
  },
  methods: {
    fetchOtherPropertiesInLoan: function(event) {
      const params = {
        trustee_loan_id: this.property.loan.trustee_loan_id
      }
      
      axios.get('/properties.json', { params })
      .then(response => {
        this.otherPropertiesInLoan = response.data.properties.filter((property) => property.id != this.property.id)
      })
      .catch(error => {
        console.log(error);
      })
    },
    updateBPieceBuyer: function(event) {
      event.target.blur()
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });

      const b_piece_buyer = {
          b_piece_buyers: event.target.innerHTML.trim()
      }

      axios.put(`/properties/${this.$route.params.id}.json`, b_piece_buyer)
      .then(response => {
        notyf.success('B-Piece Buyer information updated!');
      })
      .catch(error => {
        notyf.error('Oops, B-Piece Buyer could not be updated!');
      })
    },
    commaDelimited(value) {
      if (value == undefined || value == null) {
        return value;
      }

      return value.toLocaleString();
    },
    toLocaleDate(dateString) {
      if (dateString == undefined || dateString == null) {
        return dateString;
      }
            
      let date = new Date(`${dateString}T00:00:00Z`).toLocaleDateString('en-US', {timeZone: 'UTC'});
      
      return date; 
    },
    propertyUsesUnits() {
      if (this.property.normalized_property_type == null) {
        return
      }
      
      return this.property.normalized_property_type.startsWith('LO-') || this.property.normalized_property_type.startsWith('MF-')
    },
    unitLabel(singular = false) {
      if (this.propertyUsesUnits()) {
        return singular ? "Unit" : "Units"
      } else {
        return "Sq Ft"
      }
    },
    tenancyLabel() {
      if (this.property.occupancy != 0 && this.property.occupancy_as_of != null) {
        return `- ${this.property.occupancy}% occupied as of ${this.toLocaleDate(this.property.occupancy_as_of)}`
      }
    },
    destroyProperty() {
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      
      var result = confirm("Are you sure you want to delete this property?");
      
      if (result) {
        console.log("deleting property")
        axios.delete(`/properties/${this.$route.params.id}.json`)
        .then(response => {
          notyf.success('Property deleted successfully!');
        })
        .catch(error => {
          notyf.error('Oops, property could not be deleted!');
        })
      }
    }
  },
  
  mounted() {
    this.fetchOtherPropertiesInLoan()
  },
  
  computed: {
    maturesSoon() {
      if (Date.parse(this.property.loan.maturity_date) < Date.parse('2025-12-31')) {
        return true;
      } else {
        return false;
      }
    },
    totalExposure() {
      return this.property.recent_loan_pieces.map(loan => loan.total_exposure).reduce((prev, curr) => prev + curr)
    },
    originalBalance() {
      return this.property.recent_loan_pieces.map(loan => loan.original_balance).reduce((prev, curr) => prev + curr)
    },
    multiPropertyLoan() {
      return this.otherPropertiesInLoan > 1 || this.property.loan.multiproperty_loan
    },
    propertyHasGoogleData() {
      return !(_.isEmpty(this.property.google_data['photos']))
    },
    googlePlaceImageUrl() {
      return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photo_reference=${this.property.google_data['photos'][0]['photo_reference']}&key=AIzaSyARgDQcHIOCnLBtWd3kCdwtytxGTBmQ5G4`
    },
    units() {
      if (this.propertyUsesUnits()) {
        return this.property.number_of_units
      } else {
        return this.property.square_feet
      }
    },
    balance_per_ft_or_unit() {
      return this.property.total_balance / this.units
    },
    population() {
      try {
        return this.property.demographics.five_mile["HLTotalPopulation"]
      } catch(err) {
        return '-'
      }
    },
    appraisal_per_unit() {
      if (this.property.loan.appraisal_value_per_ft_or_unit) {
        return this.property.loan.appraisal_value_per_ft_or_unit
      } else {
        return this.property.loan.appraisal / this.units
      }
    },
    asianPopulation() {
      try {
        return this.property.demographics.five_mile["TotalPopulationAsianAlone"]
      } catch(err) {
        return '-'
      }
    },
    capRateOnAppraisal: function() {
      return ((this.property.loan.noi / this.property.loan.appraisal) * 100.0).toPrecision(3)
    },
    capRateOnBalance: function() {
      return ((this.property.loan.noi / this.property.total_balance) * 100.0).toPrecision(3)
    },
    tenantsWithSqFootage: function() {
      const tenant_prefixes = ['', 'second_', 'third_', 'fourth_', 'fifth_'];
      let tenants = {};
      
      tenant_prefixes.forEach(prefix => {
        if (this.property[`${prefix}largest_tenant`] == null) {
          return
        }
        
        tenants[this.property[`${prefix}largest_tenant`]] = {
          sq_footage: this.property[`${prefix}largest_tenant_sq_footage`], 
          lease_exp:  this.property[`${prefix}largest_tenant_lease_exp_date`]
        }
      })
      
      delete tenants['-']
            
      return tenants
    }
  }
}
</script>

<style lang="scss" scoped>
  ul {
    padding-left: 10px;
    
    li {
      list-style: none;
      font-size: 0.75rem;
      margin: 5px 0px;
    } 
  }
  
  .item {
    margin-left: 35px;
    text-align: center;
    
    p {
      color: #CCC;
      font-size: .9rem;
    }
  }
  
  table {
    table-layout: fixed;
    width: 100%;
  }
  
  .table-sm {
    font-size: .75rem;
    border-color: #DDD
  }

  tr td:nth-child(2) {
    text-align: right;
  }

  .property {
    position: fixed;
    height: 100%;
    background-color: white;
    left: 20%;
    width: 80%;
    top: 0px;
    box-shadow: 2px 2px 20px 1px;
    overflow: scroll;
  }
  
  #map {
    height: 700px;
    width: 100%;
  }
  
  p {
    margin-bottom: 0px;
  }
  
  .banner {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #4CAF50;
    color: #FFF;
  }
  
  .content-box-green {
    margin-bottom: 10px;
  }
    
  .content-box-green .content {
    overflow: hidden;
    padding: 10px;
    font-size: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #4CAF50;
  }
  
  .content-box-green .title {
    height :30px;
    line-height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #4CAF50;
    font-size: 12px;
    font-weight: bold;
    display: block;
    color: white;
    display: block;
    padding: 0px 5px;
    border: 1px solid #4CAF50;
    border-bottom: none;
  }
  
  .slide-fade-enter-active, .slide-fade-leave-active  {
    transition: all .3s ease;
  }
  
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  .nav-dir {
    padding: 0 10px 0 10px;
  }
  
  .bordered {
    border: 1px solid #DDD;
  }
</style>